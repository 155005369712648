<template>
  <section id="daftar-kegiatan-instansi">
    <b-row>
      <b-col
        lg="3"
        cols="12"
        order="2"
        order-lg="1"
      >
        <profile-about :master-id="instansi_id" />
      </b-col>

      <b-col
        lg="9"
        cols="12"
        order="1"
        order-lg="2"
      >
        <b-card>
          <b-row class="justify-content-end mb-1">
            <b-col md="4">
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length
            }"
            :group-options="{
              enabled: true
            }"
            style-class="vgt-table striped"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-header-row"
              slot-scope="props"
            >
              <span v-if="props.column.field == 'nama_kegiatan'">
                {{ props.row.label }}
              </span>
            </template>

            <template
              slot="table-row"
              slot-scope="props"
            >
              <span
                v-if="props.column.field === 'nama_kegiatan'"
                class="text-wrap"
              >
                <b-link
                  :to="{ name: 'kegiatan-detail', params: { id: props.row.data_utama_id } }"
                  class="item-review text-primary"
                >
                  {{ props.row.nama_kegiatan }}
                </b-link>
              </span>
              <span v-else-if="props.column.field === 'total_anggaran'">
                Rp. {{ Number(props.row.total_anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  :variant="statusVariant(props.row.status).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ statusVariant(props.row.status).title }}
                </b-badge>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="serverParams.length"
                    :options="['10','20','50']"
                    class="mx-1"
                    @change="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    v-model="selectPage"
                    :total-rows="props.total"
                    :per-page="serverParams.length"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BLink, BPagination, BFormInput, BFormSelect,
  BButton, BInputGroup, BInputGroupAppend, BBadge,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/instansiColumns'

import ProfileAbout from './module/PengajuanAbout.vue'

export default {
  components: {
    BBadge,
    BCard,
    BLink,
    BRow,
    BCol,
    VueGoodTable,
    BPagination,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BButton,

    ProfileAbout,
  },
  setup() {
    const { statusColors } = stColors()
    const { newColumns } = tableColumns()

    const yearBudget = Number(localStorage.getItem('tahunAnggaran'))
    const columns = newColumns

    return {
      yearBudget,
      statusColors,
      columns,
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      isLoading: false,
      userData: getUserData(),
      instansi_id: '',
      instansiData: '',
      totalRecords: 0,
      selectPage: 1,
      serverParams: {
        tahun_anggaran: this.yearBudget,
        sort_by: [
          {
            column: 'clearance_id',
            asc_desc: 'desc',
          },
        ],
        start: 0,
        length: 10,
      },
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.instansi_id = Number(this.$route.params.id)
  },
  mounted() {
    this.onColumnFilter({ columnFilters: {} })
  },
  methods: {
    statusVariant(status) {
      if (this.statusColors[status]) {
        return this.statusColors[status]
      }
      return {
        title: status,
        class: 'light-success',
      }
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ length: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      params.map(param => {
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: param.field,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      const filterz = [{
        column: 'tahun_anggaran',
        value: String(this.yearBudget),
      }]
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry

        let columName = key
        if (key === 'label') {
          columName = 'letter_number'
        }
        filterz.push({
          column: columName,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      this.rows = []
      this.serverParams.instansi_id = this.instansi_id
      this.$http.post(`/clearance/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.rows = []
            this.totalRecords = 0
          }
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
